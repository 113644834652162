
import Mentionable from '@/components/mentionable'
import atTextRender from "@/components/atTextRender"
export default {
	components: {
		Mentionable,
		atTextRender
	},
	data () {
		return {
			followedList: [],
			content: "",
			list: [],
			childData: {},
			parent_id: 0,
			parent_name: null,
			comment_id: null,
			pagination: {
				total: 0,
				pageSize: 20,
				current_page: null
			},
			loading: false
		}
	},
	computed: {
		//@ 的下拉菜单
		items () {
			return this.followedList.map(item => ({ value: item.nick_name, firstName: item.uuid, user_id: item.user_id }))
		}
	},
	created () {
		this._get_followedList()
	},
	props: {
		model_type: {
			type: String,
			default: "moment"
		}
	},
	methods: {
		//获取关注列表
		async _get_followedList () {
			const { data, status } = await this.$api.followedListApi({ uuid: this.userInfo.uuid })
			if (status === 200)
				this.followedList = data
		},
		goInfo (id) {
			this.$router.push({
				path: "/homepage/user/personal",
				query: {
					uuid: id
				}
			});
		},
		// 获取评论列表
		async _get_list (page = 1) {
			this.loading = true;
			const { uuid } = this.data;
			const { model_type } = this
			const { status, info, data, meta } = await this.$api.commentListApi({
				model_id: uuid,
				model_type,
				page
			});
			this.loading = false;
			if (status == 200) {
				this.list = data;
				this.pagination.total = meta.pagination.total;
				this.pagination.pageSize = meta.pagination.per_page;
				this.pagination.current_page = meta.pagination.current_page;
				const { first_in_comment } = this.$route.query;
				if (first_in_comment) {
					this.scroll && this.scroll("#comment-input");
				}
			} else {
				this.$message.error(info);
			}
		},
		// 获取评论详情
		async _get_commentChild (comment_id) {
			this.loading = true;
			const { status, info, data } = await this.$api.commentChildApi({
				comment_id
			});
			this.loading = false;
			if (status == 200) {
				this.$set(this.childData, data.comment_id, data.children);
				console.log(this.childData);
			} else {
				this.$message.error(info);
			}
		},
		// 发起评论
		async _set_comment () {
			this.loading = true;
			const { status, info } = await this.$api.setCommentApi({
				model_id: this.data.uuid,
				parent_id: this.parent_id,
				content: this.content,
				mentions: this.get_mentions()
			});
			this.loading = false;
			if (status == 200) {
				this.set_comment_success()
				this.$message.success(info);
			} else {
				this.$message.error(info);
			}
		},
		// 发起评论成功
		set_comment_success () {
			if (this.comment_id) {
				this._get_commentChild(this.comment_id);
				this.scroll && this.scroll("#COMENT" + this.comment_id);
			} else {
				this._get_list(this.pagination.current_page);
			}
			this.content = "";
			this.clearReply();
		},
		// 清除评论详情
		clearCommentChild (comment_id) {
			this.$set(this.childData, comment_id, null);
		},
		// 发起回复
		reply (parent_id, parent_name, comment_id) {
			this.parent_id = parent_id;
			this.parent_name = parent_name;
			this.comment_id = comment_id;
			this.scroll && this.scroll("#comment-input");
		},
		//清除回复
		clearReply () {
			this.parent_id = 0;
			this.parent_name = null;
			this.comment_id = null;
		},
		//  获取At的人
		get_mentions () {
			function getExecStrs (str) {
				var reg = /@(.+?)\s/g
				var list = []
				var result = null
				do {
					result = reg.exec(str)
					result && list.push(result[1])
				} while (result)
				return list
			}
			var str = this.content
			let atUserList = getExecStrs(str)
			console.log(this.$refs.at)
			let mentions = []
			atUserList.forEach(item => {
				this.followedList.forEach(itm => {
					if (item == itm.nick_name) {
						mentions.push({
							user_id: itm.user_id,
							nick_name: item,
							uuid: itm.uuid
						})
					}
				})
			})
			return mentions
		},
	}
}
/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-12 11:44:06
 * @Module: 转发弹出框
 */
 <template>
  <div class="transpond-dialog-box">
    <el-dialog title="转发"
               :visible.sync="transpondDialogVisible"
               destroy-on-close
               width="600"
               :loading="loading">
      <div class="transpond-dialog-content"
           v-if="transpondDialogVisible">
        <div class="transpond-dialog-content-t">
          <el-image class="transpond-dialog-content-t-l"
                    v-if="imagesrc"
                    :src="imagesrc"
                    fit="cover"></el-image>
          <div class="transpond-dialog-content-t-r">
            <span>@{{data.user_name}}：</span>
            <span>{{text||"--"}}</span>
          </div>
        </div>
        <div class="transpond-dialog-content-b">
          <Mentionable :keys="['@']"
                       :items="items"
                       insert-space
                       ref="at"
                       offset="6">
            <el-input type="textarea"
                      :rows="6"
                      resize="none"
                      placeholder="请输入内容"
                      maxlength="1000"
                      show-word-limit
                      v-model="content">
            </el-input>
          </Mentionable>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary"
                   @click="_req_share">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 <script>
import Mentionable from '@/components/mentionable'
import userinfo from "@/mixins/userinfo"
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Mentionable
  },
  mixins: [userinfo],
  data () {
    return {
      content: '',
      loading: false,
      followedList: []
    };
  },
  mounted () {
    this._get_followedList()
  },
  methods: {
    // 请求转发
    async _req_share () {
      this.loading = true
      const { data: { uuid: share_id }, content } = this
      const mentions = this.get_mentions()
      const { status, info } = await this.$api.publishMomentShareApi({ share_id, content, mentions })
      if (status == 200) {
        this.$message.success(info)
        this.$emit('close', true)
      } else {
        this.$message.error(info)
      }
      this.loading = false
    },
    //获取关注列表
    async _get_followedList () {
      const { data, status } = await this.$api.followedListApi({ uuid: this.userInfo.uuid })
      if (status === 200)
        this.followedList = data
    },
    //  获取At的人
    get_mentions () {
      function getExecStrs (str) {
        var reg = /@(.+?)\s/g
        var list = []
        var result = null
        do {
          result = reg.exec(str)
          result && list.push(result[1])
        } while (result)
        return list
      }
      var str = this.content
      let atUserList = getExecStrs(str)
      console.log(this.$refs.at)
      let mentions = []
      atUserList.forEach(item => {
        this.followedList.forEach(itm => {
          if (item == itm.nick_name) {
            mentions.push({
              user_id: itm.user_id,
              nick_name: item,
              uuid: itm.uuid
            })
          }
        })
      })
      return mentions
    },
  },
  computed: {
    transpondDialogVisible: {
      get: function () {
        return this.visible
      },
      set: function () {
        this.$emit('close', false)
      }
    },
    imagesrc () {
      if (this.data.type === 0) {
        return this.data.moment_content.medias[0]
      } else if (this.data.type === 1) {
        return this.data.moment_content.images ? this.data.moment_content.images[0] : ''
      } else if (this.data.type === 2) {
        return this.data.moment_content.video_cover
      } else if (this.data.type === 4) {
        return this.data.moment_content.video_cover
      } else if (this.data.type === 5) {
        return this.data.moment_content.cover
      } else {
        return ''
      }
    },
    text () {
      if (this.data.type === 0) {
        return this.data.moment_content.content
      } else if (this.data.type === 1) {
        return this.data.moment_content.title
      } else if (this.data.type === 2) {
        return this.data.moment_content.title
      } else if (this.data.type === 3) {
        return this.data.moment_content.shareable_description
      } else if (this.data.type === 4) {
        return this.data.moment_content.content
      } else if (this.data.type === 5) {
        return this.data.moment_content.title
      } else {
        return ''
      }
    },
    //@ 的下拉菜单
    items () {
      return this.followedList.map(item => ({ value: item.nick_name, firstName: item.uuid, user_id: item.user_id }))
      // if (this.followedList) {
      // } else {
      //   return []
      // }
    }
  }
};
 </script>
 <style lang='scss' scoped>
.transpond-dialog-content {
  height: 322px;
  background: #f7f7f7;
  border: #f7f7f7 solid 1px;
  &-t {
    margin: 30px 20px 10px 20px;
    min-height: 20px;
    background: #e3e3e3;
    padding: 10px 16px;
    border-radius: 4px;
    display: flex;
    &-l {
      width: 158px;
      height: 102px;
      border-radius: 4px;
      // background: #f66;
      margin-right: 10px;
    }
    &-r {
      height: 100%;
      // background: #f6f;
      flex: 1;
      line-height: 20px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5; // 控制多行的行数
      -webkit-box-orient: vertical;

      & :first-child {
        color: #5071a5;
      }
      & :last-child {
        color: #333333;
      }
    }
  }
  &-b {
    padding: 0 20px;
  }
}
.transpond-dialog-box /deep/ .el-dialog__body {
  padding: 0px;
}
</style>
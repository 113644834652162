/* 
 * @Author: 曹俊杰 
 * @Date: 2021-01-05 17:41:12
 * @Module: @ 功能文本的渲染
 */
 <template>
  <span class="hover atTextRender"
        style="width:100%; word-wrap:break-word; "
        v-html="content"
        @click.stop="click" />
  <!-- <span v-for="(item,index) in textnode"
          :key="index">
      <span v-if="name.indexOf(item.replace('@', '')) !==-1"
            @click.stop="toUser(item)"
            style="color:#8BAADE">{{item}}&nbsp;</span>
      <span v-else
            v-html="item">&nbsp;</span>
    </span> -->
  <!-- </span> -->
  <!-- <div style="width:100%; word-wrap:break-word; ">
      1111111111111111111111111111222222222222222222222222222222222222222222233333333333333333333333333333333333333333334444444444444444
    </div> -->
</template>
 <script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {
    toUser (item) {
      const name = item.replace("@", "")
      const { mentions } = this.data
      const { user_uuid } = mentions[mentions.findIndex(itm => itm.user_name == name)]
      if (user_uuid)
        this.$store.dispatch("homepage/toPersonal", { uuid: user_uuid, newPage: true })
    },
    click (event) {
      if (event.target.nodeName === 'SPAN' && event.target.className === 'user') {
        // 获取触发事件对象的属性
        this.toUser(event.target.innerHTML)
      }
    }
  },
  computed: {
    textnode () {
      return this.text ? this.text.split(" ").map(item => item.replace(/\n/g, "<br/>")) : []
    },
    name () {
      if (this.data.mentions) {
        return this.data.mentions.map(item => {
          return item.user_name
        }).join("_")
      } else {
        return ""
      }
    },
    content () {
      let content = ""
      this.textnode.forEach(item => {
        if (this.name.indexOf(item.replace('@', '')) !== -1) {
          content = content + `<span style="color:#8BAADE;display: inline;" class='user'> ${item} </span>`
        } else {
          content = content + item
        }
      });
      return content
    }
  }
};
 </script>
 <style lang='scss' scoped>
.atTextRender {
  width: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  border-color: #f2f2f2;
}
</style>